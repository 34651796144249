import React from 'react';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import Flogo from '../../assets/images/footer-logo.svg';
import GooglePay from '../../assets/images/google-pay.svg';
import AppStore from '../../assets/images/app-store.svg';

function Footer() {
  const duration = 500;
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <footer>
        <div className="footer-top">
          <div className="custom-container">
            <div className="custom-row">
              <div className="custom-col-4" data-aos="fade-up">
                <div className="text">
                  <a href="#" title="Menu">
                    <img src={Flogo} alt="Menu" title="Menu" />{' '}
                  </a>
                  {/* <p>
                    Task deadlines and scheduling applications with various
                    benefits and advantages
                  </p> */}
                  <ul className="social-wrap">
                    <li>
                      <a
                        href="https://www.facebook.com/whichmenuapp"
                        title="Facebook"
                        target="blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/whichmenu"
                        title="Twitter"
                        target="blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/whichmenu/?hl=en"
                        title="Linkedin"
                        target="blank"
                      >
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="custom-col-3" data-aos="fade-up">
                <h4>Company</h4>
                <ul className="menu-list">
                  <li>
                    <Link
                      activeClass="active"
                      to="section-1"
                      spy
                      smooth
                      duration={duration}
                      className="nav-link"
                      title="Home"
                    >
                      Home{' '}
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      activeClass="active"
                      to="section-2"
                      spy
                      smooth
                      duration={duration}
                      className="nav-link"
                      title="Features"
                    >
                      {' '}
                      Features{' '}
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      activeClass="active"
                      to="section-3"
                      spy
                      smooth
                      duration={duration}
                      className="nav-link"
                      title="About Us"
                    >
                      {' '}
                      About Us
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      activeClass="active"
                      to="section-4"
                      spy
                      smooth
                      duration={duration}
                      className="nav-link"
                      title="App Tour"
                    >
                      App Tour
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      activeClass="active"
                      to="section-5"
                      spy
                      smooth
                      duration={duration}
                      className="nav-link"
                      title="Subscription"
                    >
                      Subscription
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <Link
                      to="section-6"
                      spy
                      smooth
                      duration={duration}
                      title="Contact"
                      className="contact"
                    >
                      Contact
                    </Link>
                  </li>
                  <li>
                    {' '}
                    <a
                      href="https://whichmenu.com/register"
                      className="nav-link"
                      target="blank"
                    >
                      Register Your Restaurant 
                    </a>
                  </li>
                </ul>
              </div>
              <div className="custom-col-3" data-aos="fade-up">
                <h4>Legal</h4>
                <ul className="menu-list">
                  {/* <li onClick={() => navigate('faq')}>
                    {' '}
                    <a className="nav-link" title="FAQ">
                      FAQ
                    </a>{' '}
                  </li> */}
                  <li>
                    {' '}
                    <a
                      href="https://www.whichmenuapp.com/_files/ugd/6965a7_a9800b41104c42d6865d0bf6c4ede6c7.pdf"
                      className="nav-link"
                      title="Terms & Conditions"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>{' '}
                  </li>
                  <li>
                    {' '}
                    <a
                      href="https://www.whichmenuapp.com/privacy-policy"
                      className="nav-link"
                      title="Privacy Policies"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>{' '}
                  </li>
                </ul>
              </div>
              <div className="custom-col-2" data-aos="fade-up">
                <h4>Download</h4>
                <div className="download-wrap">
                  <a
                    href="https://play.google.com/store/apps/details?id=reraisedesign.com.whichmenu"
                    title="Google Pay"
                    data-aos="zoom-in"
                    target="blank"
                  >
                    {' '}
                    <img src={GooglePay} alt="Google Pay" title="Google Pay" />
                  </a>
                  <a
                    href="https://itunes.apple.com/us/app/which-menu/id1239180271?ls=1&mt=8"
                    title="App Store"
                    data-aos="zoom-in"
                    target="blank"
                  >
                    {' '}
                    <img src={AppStore} alt="App Store" title="App Store" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2022 whichmenu. All rights reserved.</p>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
