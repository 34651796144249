import React from 'react';

const PrivacyPolicyPage = () => {

    return (
        <>

            <div className="faqSection">
                <div className="custom-container t-center">
                    <h1>Privacy And Policy</h1>
                    <div className="t-center">
                        <p>Which Menu - Privacy &amp; Cookies Policy</p>
                        <p>Effective date: July 25, 2023</p>
                        <p>Which Menu respects your privacy and protecting your personal data is very important to us.</p>
                        <p>This Privacy &amp; Cookies Policy describes Which Menu policies and procedures on the collection,</p>
                        <p>use and disclosure of your information when you use the services, websites, and applications</p>
                        <p>offered by Which Menu (the &quot;Services&quot;) and tells you about your privacy rights and how the law</p>
                        <p>protects you. By using the Services, you consent to our use of your information in accordance</p>
                        <p>with this Privacy &amp; Cookies Policy. We will not use or share your personal information with</p>
                        <p>anyone except as described in this Privacy &amp; Cookies Policy.</p>
                        <p>Capitalized terms that are not defined in this Privacy &amp; Cookies Policy have the meaning given</p>
                        <p>them in our T&amp;C&rsquo;s</p>
                        <p>With that in mind, this Privacy and Cookies Policy is designed to describe:</p>
                        
                        
                        
                        <p>● Who we are and how to contact us</p>
                        <p>● Your rights relating to your Personal Data</p>
                        <p>● Marketing communications preferences</p>
                        <p>● What Personal Data we collect</p>
                        <p>● How we use your Personal Data and why</p>
                        <p>● How we use cookies and other tracking or profiling technologies</p>
                        <p>● Who we share your Personal Data with</p>
                        <p>● How long we store your Personal Data</p>
                        <p>● Where we store your Personal Data</p>
                        <p>● How we protect your Personal Data</p>
                        <p>● Our policy on children&apos;s privacy</p>
                        <p>●Links to other websites</p>
                        <p>● Changes to our Privacy &amp; Cookies Policy</p>
                        <p>This Privacy &amp; Cookies Policy is intended to meet our duties of transparency under the &quot;General</p>
                        <p>Data Protection Regulation&quot; or &quot;GDPR&quot;.</p>
                        <p>We will post any modifications or changes to this Privacy &amp; Cookies Policy on this page.</p>
                        <p>WHO WE ARE AND HOW TO CONTACT US</p>
                        <p>Who we are. WM UK GROUP (for the purposes of the GDPR) of your Personal Data</p>
                        <p>(referred to as either &quot;Which Menu&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot; in this Privacy &amp; Cookies Policy). Our</p>
                        <p>address is: 7 Lingwell Park, Widnes, WA8 9YP</p>
                        <p>How to contact us. If you have any questions about our practices or this Privacy &amp; Cookies</p>
                        <p>Policy, please contact us at: <a data-fr-linked="true" href="mailto:privacy@whichmenu.com">privacy@whichmenu.com</a></p>
                        <p>YOUR RIGHTS RELATING TO YOUR PERSONAL DATA</p>
                        <p>You have the right under this Privacy and Cookies Policy, and by law if you are within the EU, to:</p>
                        
                        <p>● Request access to your Personal Data. If you are within the EU, this enables you to</p>
                        <p>receive a copy of the Personal Data we hold about you and to check that we are lawfully</p>
                        <p>processing it.</p>
                        <p>● Request correction of the Personal Data that we hold about you. This enables you to</p>
                        <p>have any incomplete or inaccurate information we hold about you corrected.●</p>
                        
                        <p>● Request erasure of your Personal Data. This enables you to ask us to delete or</p>
                        <p>remove Personal Data where there is no good reason for us continuing to process it. You</p>
                        <p>also have the right if you are within the EU to ask us to delete or remove your Personal</p>
                        <p>Data where you have exercised your right to object to processing (see below).</p>
                        <p>● Object to processing of your Personal Data. This right exists where we are relying</p>
                        <p>on a legitimate interest as the legal basis for our processing and there is something</p>
                        <p>about your particular situation, which makes you want to object to processing of your</p>
                        <p>Personal Data on this ground. You also have the right to object where we are</p>
                        <p>processing your Personal Data for direct marketing purposes.</p>
                        <p>● Request the restriction of processing of your Personal Data. This enables you to</p>
                        <p>ask us to suspend the processing of Personal Data about you, for example if you want</p>
                        <p>us to establish its accuracy or the reason for processing it.</p>
                        <p>● Request the transfer of your Personal Data. If you are within the EU, we will provide to</p>
                        <p>you, or a third party you have chosen, your Personal Data in a structured, commonly</p>
                        <p>used, machine-readable format. Note that this right only applies to automated information</p>
                        <p>which you initially provided consent for us to use or where we used the information to</p>
                        <p>perform a contract with you.</p>
                        <p>● Withdraw consent. This right only exists where we are relying on consent to process</p>
                        <p>your Personal Data (&quot;Consent Withdrawal&quot;). If you withdraw your consent, we may not be</p>
                        <p>able to provide you with access to the certain specific functionalities of our Site. We will</p>
                        <p>advise you if this is the case at the time you withdraw your consent.</p>
                        <p>How to exercise your rights. If you want to exercise any of the rights described above, please</p>
                        <p>contact us using the contact details in Who We Are and How to Contact Us.</p>
                        <p>Typically, you will not have to pay a fee to access your Personal Data (or to exercise any of the</p>
                        <p>other rights). However, except in relation to Consent Withdrawal, we may charge a reasonable</p>
                        <p>fee if your request is clearly unfounded, repetitive or excessive, or, we may refuse to comply with</p>
                        <p>your request in these circumstances.</p>
                        <p>We may need to request specific information from you to help us confirm your identity and</p>
                        <p>ensure your right to access your Personal Data (or to exercise any of your other rights). This is a</p>
                        <p>security measure to ensure that Personal Data is not disclosed to any person who has no right to</p>
                        <p>receive it. We may also contact you to ask you for further information in relation to your request</p>
                        <p>to speed up our response.</p>
                        <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer</p>
                        <p>than a month if your request is particularly complex or you have made a number of requests. In</p>
                        <p>this case, we will notify you and keep you updated.</p>
                        <p>Complaints</p>
                        <p>If you would like to submit a complaint regarding this Privacy Policy or our practices in relation to</p>
                        <p>your Personal Data, please contact us at: <a data-fr-linked="true" href="mailto:privacy@whichmenu.com">privacy@whichmenu.com</a></p>
                        <p>We will reply to your complaint as soon as we can.</p>
                        <p>If you feel that your complaint has not been adequately resolved, please note that if you are in</p>
                        <p>the EU the GDPR gives you the right to contact your local data protection supervisory authority,</p>
                        <p>which for the UK, is the Information Commissioner&apos;s Office.MARKETING COMMUNICATIONS PREFERENCES</p>
                        <p>You can ask us to stop sending you marketing messages or modify your email preferences at</p>
                        <p>any time through any of the following methods:</p>
                        <p>●by following the opt-out links on any marketing message sent to you; or</p>
                        <p>●by contacting us at any time using the contact details in Who We Are and How to Contact</p>
                        <p>Us.</p>
                        <p>Where you opt out of receiving these marketing messages, this will not apply to Personal Data</p>
                        <p>provided to us as a result of emails relating to existing or pending issues, enquiries to do with</p>
                        <p>your account using the Services or consent to direct marketing communications.</p>
                        <p>WHAT PERSONAL DATA WE COLLECT</p>
                        <p>Which Menu uses Personal Data we collect to provide the Services, personalize content,</p>
                        <p>remember information to help you efficiently access your account, analyze how the Services are</p>
                        <p>used, diagnose service or technical problems, maintain security, monitor aggregate metrics such</p>
                        <p>as total number of visitors, traffic, and demographic patterns, and track user content and users</p>
                        <p>as necessary to comply with the Digital Millennium Copyright Act and other applicable laws.</p>
                        <p>Information You Directly Provide to Us. There are many occasions when you provide</p>
                        <p>information that may enable us to identify you personally (&quot;Personal Data&quot;) while using the</p>
                        <p>Services. The Personal Data we may collect from you is outlined in the table below.</p>
                        <p>Category of Personal Data collected</p>
                        <p>Identity Data</p>
                        <p>Contact Data</p>
                        <p>Professional Background Data</p>
                        <p>Online Presence Data</p>
                        <p>Financial Data</p>
                        <p>Transaction Data</p>
                        <p>Content Data</p>
                        <p>What this means</p>
                        <p>First name, surname, maiden name, last name,</p>
                        <p>username or similar identifier, marital status, title,</p>
                        <p>date of birth and gender, picture, social security</p>
                        <p>number and/or tax identification number, password,</p>
                        <p>copies of ID cards or other forms of identification.</p>
                        <p>Your home address, work address, billing address,</p>
                        <p>email address and telephone numbers.</p>
                        <p>Educational and professional history, interests and</p>
                        <p>accomplishments, job and salary preferences, third</p>
                        <p>party recommendations, projects completed,</p>
                        <p>professional resumes and CVs, information about co-</p>
                        <p>workers, your interests in certain job openings, job</p>
                        <p>applications, interviewing information, and hiring</p>
                        <p>results.</p>
                        <p>Links to your public account pages at social media</p>
                        <p>websites, links to personal websites, and other online</p>
                        <p>materials related to you.</p>
                        <p>Your bank account and payment card details,</p>
                        <p>statements about your wealth and financial situation.</p>
                        <p>Any details about payments to and from you and</p>
                        <p>other details of subscriptions and services you have</p>
                        <p>purchased from us. Data in respect of your</p>
                        <p>transactions with third parties (including your credit</p>
                        <p>history).</p>
                        <p>Any content you post to the Services not already</p>
                        <p>included in another category, including without</p>
                        <p>limitation, your profiles, questions, preference</p>
                        <p>settings, answers, messages, comments, and otherMarketing and Communications Data</p>
                        <p>Behavioral Data</p>
                        <p>Technical Data</p>
                        <p>contributions on the Services, and metadata about</p>
                        <p>them (such as when you posted them) ( &quot;Content&quot;).</p>
                        <p>Your preferences in receiving marketing from us and</p>
                        <p>our third parties and your communication</p>
                        <p>preferences. If you correspond with us by email or</p>
                        <p>messaging through the Services, we may retain the</p>
                        <p>content of such messages and our responses.</p>
                        <p>Inferred or assumed information relating to your</p>
                        <p>behavior and interests, based on your online activity.</p>
                        <p>This is most often collated and grouped into</p>
                        <p>&quot;segments.&quot;</p>
                        <p>Internet protocol (IP) address, your login data,</p>
                        <p>browser type and version, time zone setting and</p>
                        <p>location, browser plug-in types and versions,</p>
                        <p>operating system and platform and other technology</p>
                        <p>on the devices you use to access this website or use</p>
                        <p>our services.</p>
                        <p>Personal Data from Third Party Sources. In addition to the Personal Data that we collect</p>
                        <p>directly from you (as described in the section immediately above this one), we may also collect</p>
                        <p>certain of your Personal Data from third party sources, some of which may not be publicly</p>
                        <p>available. Examples of these sources are broken down in the table below.</p>
                        <p>Third party data source</p>
                        <p>Credit Reporting Agencies</p>
                        <p>Social Media Sites</p>
                        <p>Our Affiliates</p>
                        <p>Analytics Providers</p>
                        <p>Advertisers</p>
                        <p>Data Brokers</p>
                        <p>Category(ies) or other types of</p>
                        <p>Personal Data received.</p>
                        <p>● Identity Data</p>
                        <p>●Contact Data</p>
                        <p>●Financial Data</p>
                        <p>●Transaction Data</p>
                        <p>●Identity Data</p>
                        <p>●Contact Data</p>
                        <p>●Online Presence Data</p>
                        <p>●Identity Data</p>
                        <p>●Contact Data</p>
                        <p>●Marketing and Communications</p>
                        <p>Data</p>
                        <p>●Behavioral Data</p>
                        <p>●Investment Data</p>
                        <p>●Transaction Data</p>
                        <p>●Financial Data</p>
                        <p>●Content Data</p>
                        <p>●Behavioral Data</p>
                        <p>●Technical Data</p>
                        <p>●Behavioral Data</p>
                        <p>●Technical Data</p>
                        <p>●Identity DataIdentity Verification and Compliance Service Providers</p>
                        <p>●Contact Data</p>
                        <p>●Behavioral Data</p>
                        <p>●Technical Data</p>
                        <p>●Online Presence Data</p>
                        <p>●Identity Data</p>
                        <p>●Contact Data</p>
                        <p>Aggregated Data. We may also collect, use and share &quot;Aggregated Data&quot; such as statistical or</p>
                        <p>demographic data for any purpose. Aggregated Data may be derived from your Personal Data,</p>
                        <p>but once in aggregated form it will not constitute Personal Data for the purposes of the GDPR as</p>
                        <p>this data does not directly or indirectly reveal your identity. However, if we combine or connect</p>
                        <p>Aggregated Data with your Personal Data so that it can directly or indirectly identify you, we treat</p>
                        <p>the combined data as Personal Data which will be used in accordance with this Privacy &amp;</p>
                        <p>Cookies Policy.</p>
                        <p>No Special Categories of Personal Data. We do not collect any &quot;Special Categories of</p>
                        <p>Personal Data&quot; about you (this includes details about your race or ethnicity, religious or</p>
                        <p>philosophical beliefs, sexual orientation, political opinions, trade union membership, information</p>
                        <p>about your health and genetic and biometric data). Nor do we collect any information about</p>
                        <p>criminal convictions and offences.</p>
                        <p>HOW WE USE YOUR PERSONAL DATA AND WHY</p>
                        <p>We generally use Personal Data for the following: to deliver and improve our Services; to</p>
                        <p>manage your account and provide you with customer support; to perform research and analysis</p>
                        <p>about your use of the Services; to develop, display, and track Content and advertising tailored to</p>
                        <p>your interests on the Services and other sites, including, potentially, providing our</p>
                        <p>advertisements to you when you visit other sites; website or mobile application analytics; to</p>
                        <p>diagnose or fix technology problems; to automatically update the Services on your device; to</p>
                        <p>verify your identify and prevent fraud or other unauthorized or illegal activity; to enforce or</p>
                        <p>exercise any rights in our Terms and Conditions.</p>
                        <p>In respect of each of the purposes for which we use your Personal Data, the GDPR requires us</p>
                        <p>to ensure that we have a legal basis for that use if you are within the EU. The legal bases</p>
                        <p>depend on the Services you use and how you use them. This means we collect and use your</p>
                        <p>Personal Data only where:</p>
                        <p>●We need it to provide you the Services, including to operate the Services, provide</p>
                        <p>customer support and personalized features and to protect the safety and security of</p>
                        <p>the Services;</p>
                        <p>●It satisfies a legitimate interest (which is not overridden by your data protection</p>
                        <p>interests), such as for research and development, to market and promote the Services</p>
                        <p>and to protect our legal rights and interests; or</p>
                        <p>●We need to process your data to comply with a legal or regulatory obligation.</p>
                        <p>We may also rely on your consent as a legal basis for using your Personal Data where we have</p>
                        <p>expressly sought it for a specific purpose. If we do rely on your consent to a use of your Personal</p>
                        <p>Data, you have the right to change your mind at any time (but this will not affect any processing</p>
                        <p>that has already taken place). We have set out below, in a table format, more detailed examples</p>
                        <p>of relevant purposes for which we may use your Personal Data.Purpose</p>
                        <p>Why do we do this</p>
                        <p>Providing, updating, and maintaining To deliver the Services you have requested, including, for</p>
                        <p>our Services, Site and business</p>
                        <p>example, registering you as a user, managing your</p>
                        <p>account and profile, and authenticating you when you log</p>
                        <p>in.</p>
                        <p>Research and development</p>
                        <p>To enable us to improve the Services and better</p>
                        <p>understand our users and the markets in which we</p>
                        <p>operate. For example, we may conduct or facilitate</p>
                        <p>research and use learnings about how people use our</p>
                        <p>Services and feedback provided directly to us to</p>
                        <p>troubleshoot and to identify trends, usage, activity patterns,</p>
                        <p>areas for additional features and improvement of the</p>
                        <p>Services and other insights. We also test and analyze</p>
                        <p>certain new features with some users before introducing</p>
                        <p>the feature to all users.</p>
                        <p>Communicating with users about the To send communications via email and within the Services,</p>
                        <p>Services</p>
                        <p>including, for example, responding to your comments,</p>
                        <p>questions and requests, providing customer support, and</p>
                        <p>sending you technical notices, product updates, security</p>
                        <p>alerts, and administrative, billing and account management</p>
                        <p>related messages. We may also provide tailored</p>
                        <p>communications based on your activity and interactions</p>
                        <p>with us. For example, we may provide you with updates</p>
                        <p>regarding application / website updates, notify you with</p>
                        <p>offer ideas, and providing information regarding your</p>
                        <p>access through the Site. These communications are part</p>
                        <p>of the Services and in most cases you cannot opt out of</p>
                        <p>them. If an opt out is available,</p>
                        <p>you will find that option within the communication itself.</p>
                        <p>Providing customer support</p>
                        <p>To resolve technical issues you encounter, to respond to</p>
                        <p>your requests for assistance, comments and questions, to</p>
                        <p>analyze crash information, to repair and improve the</p>
                        <p>Services and provide other customer support.</p>
                        <p>Enhancing security</p>
                        <p>To keep our website, our Services and associated systems</p>
                        <p>operational and secure, including, for example, verifying</p>
                        <p>accounts and activity, monitoring and investigating</p>
                        <p>suspicious or fraudulent activity and to identify violations of</p>
                        <p>our terms and policies.</p>
                        <p>Marketing, promoting and driving</p>
                        <p>To send promotional communications that may be of</p>
                        <p>engagement with the Services and specific interest to you, including, for example, by email</p>
                        <p>third-party products and services</p>
                        <p>and by displaying products, better plans, events,</p>
                        <p>promotions and other information on our Site or other</p>
                        <p>companies&apos; websites. These communications may be</p>
                        <p>aimed at driving engagement and maximizing what you get</p>
                        <p>out of the Services or promoting third-party products and</p>
                        <p>services. You generally can control whether you receive</p>
                        <p>these communications as described in this policy under</p>
                        <p>Marketing Communications Preferences.</p>
                        <p>To comply with applicable law, legal As we believe is reasonably necessary to comply with a</p>
                        <p>process and regulations and law, regulation, order, subpoena, rule of a self-regulatory</p>
                        <p>protect</p>
                        <p>legitimate</p>
                        <p>business organization or audit or to protect the safety of any person,</p>
                        <p>to address fraud, security or technical issues, or to protect</p>
                        <p>interestsour legal rights, interests and the interests of others, such</p>
                        <p>as, for example, in connection with with the acquisition,</p>
                        <p>merger or sale of securities or a business (e.g. due</p>
                        <p>diligence).</p>
                        <p>What happens when you do not provide necessary Personal Data? Where we need to</p>
                        <p>process your Personal Data either to comply with law, or to perform the terms of a contract we</p>
                        <p>have with you and you fail to provide that data when requested, we may not be able to perform</p>
                        <p>the contract we have or are trying to enter into with you (for example, to provide you with the</p>
                        <p>functionalities of the Services). In this case, we may have to stop you using our Services.</p>
                        <p>HOW WE USE COOKIES AND OTHER TRACKING OR PROFILING</p>
                        <p>TECHNOLOGIES</p>
                        <p>Which Menu may collect non-personally identifiable information regarding your usage of the</p>
                        <p>Services, including the pages and articles and presentations you viewed, time spent using</p>
                        <p>certain features of the website, demographic data such as server locations, connection speed,</p>
                        <p>and other information that does not identify you. Like most online services, we also use cookies,</p>
                        <p>log files, clear GIFs, tracking pixels, web beacons, and other technologies that may collect</p>
                        <p>Personal Data.</p>
                        <p>Cookies:</p>
                        <p>What are cookies? When you visit the Services, we may send one or more &quot;cookies&quot; &ndash; small</p>
                        <p>data files &ndash; to your computer to uniquely identify your browser and let Which Menu help you log</p>
                        <p>in faster and enhance your navigation through the Site. A cookie may convey anonymous</p>
                        <p>information about how you browse the Services to us so we can provide you with a more</p>
                        <p>personalized experience, but does not collect personal information about you. A persistent</p>
                        <p>cookie remains on your computer after you close your browser so that it can be used by your</p>
                        <p>browser on subsequent visits to the Service. Persistent cookies can be removed by following</p>
                        <p>your web browser&apos;s directions. A session cookie is temporary and disappears after you close</p>
                        <p>your browser.</p>
                        <p>What cookies do we use? Our Site uses the following types of cookies for the purposes set out</p>
                        <p>below:</p>
                        <p>Type of cookie</p>
                        <p>Essential Cookies</p>
                        <p>Functionality Cookies</p>
                        <p>Analytics and Performance Cookies</p>
                        <p>Purpose</p>
                        <p>These cookies are essential to provide you with</p>
                        <p>services available through our Site and to enable you</p>
                        <p>to use some of its features. For example, they allow</p>
                        <p>you to log in to secure areas of our Site and help the</p>
                        <p>content of the pages you request to load quickly.</p>
                        <p>Without these cookies, the Services that you have</p>
                        <p>asked for cannot be provided, and we only use these</p>
                        <p>cookies to provide you with those services.</p>
                        <p>These cookies allow our Site to remember choices</p>
                        <p>you make when you use our Site, such as</p>
                        <p>remembering your login details and remembering the</p>
                        <p>changes you make to other parts of our Site which</p>
                        <p>you can customize. The purpose of these cookies is</p>
                        <p>to provide you with a more personal experience and</p>
                        <p>to avoid you having to re-enter your preferences</p>
                        <p>every time you visit our Site.</p>
                        <p>These cookies are used to collect information about</p>
                        <p>traffic to our Site and how users use our Site. TheTargeted and advertising cookies</p>
                        <p>Social Media Cookies</p>
                        <p>information gathered via these cookies does not</p>
                        <p>&quot;directly&quot; identify any individual visitor. However, it</p>
                        <p>may render such visitors &quot;indirectly identifiable&quot;. This</p>
                        <p>is because the information collected is typically linked</p>
                        <p>to a pseudonymous identifier associated with the</p>
                        <p>device you use to access our Site. The information</p>
                        <p>collected is aggregated and anonymous. We use this</p>
                        <p>information to help operate our Site more efficiently,</p>
                        <p>to gather broad demographic information and to</p>
                        <p>monitor the level of activity on our Site. We use a</p>
                        <p>number of different tools including Google Analytics,</p>
                        <p>Segment, Amplitude, Intercom, and Fullstory for this</p>
                        <p>purpose.</p>
                        <p>These cookies track your browsing habits to enable</p>
                        <p>us to show advertising which is more likely to be of</p>
                        <p>interest to you. These cookies use information about</p>
                        <p>your browsing history to group you with other users</p>
                        <p>who have similar interests. Based on that</p>
                        <p>information, and with our permission, third party</p>
                        <p>advertisers can place cookies to enable them to</p>
                        <p>show adverts which we think will be relevant to your</p>
                        <p>interests while you are on third party websites. Third</p>
                        <p>party advertisers may also use other technologies in</p>
                        <p>addition to cookies placed on the Site (such as</p>
                        <p>JavaScript, or web beacons) to measure the</p>
                        <p>effectiveness of their advertisements and to</p>
                        <p>personalize the advertising</p>
                        <p>content.</p>
                        <p>These cookies are used when you share information</p>
                        <p>using a social media sharing button or &quot;like&quot; button</p>
                        <p>on our Site or you link your account or engage with</p>
                        <p>our content on or through a social networking</p>
                        <p>website such as Facebook, Twitter or Google+. The</p>
                        <p>social network will record that you have done this.</p>
                        <p>These cookies may also include certain code that</p>
                        <p>has been placed on the Site to help track</p>
                        <p>conversions from ads, optimize ads based on</p>
                        <p>collected data, build targeted audiences for future</p>
                        <p>ads, and remarket to qualified leads&mdash;users who</p>
                        <p>have already taken</p>
                        <p>certain action on the Site.</p>
                        <p>How can I disable cookies? You can typically reset your web browser to refuse all cookies or to</p>
                        <p>notify you when a cookie is being sent. In order to do this, follow the instructions provided by</p>
                        <p>your browser (usually located within the &quot;settings&quot;, &quot;help&quot; &quot;tools&quot; or &quot;edit&quot; facility). Many browsers</p>
                        <p>are set to accept cookies until you change your settings.</p>
                        <p>If you do not accept our cookies, you may experience some inconvenience in your use of our</p>
                        <p>Services and some features of the Service may not function properly. For example, we may not</p>
                        <p>be able to recognize your computer or mobile device and you may need to log in every time you</p>
                        <p>visit our Services.</p>
                        <p>Further information about cookies, including how to see what cookies have been set on your</p>
                        <p>computer or mobile device and how to manage and delete them,</p>
                        <p>visit: <a data-fr-linked="true" href="//www.allaboutcookies.org">www.allaboutcookies.org</a> and <a data-fr-linked="true" href="//www.youronlinechoices.com">www.youronlinechoices.com</a>.</p>
                        <p>Log Files: Log file information is automatically reported by your browser each time you access</p>
                        <p>a web page. When you use the Services, our servers automatically record certain information</p>
                        <p>your web browser sends whenever you visit any website. These server logs may include</p>
                        <p>informationsuch as your web request, Internet Protocol address, browser type, referring / exit pages and</p>
                        <p>URLs, number of clicks, domain names, landing pages, pages viewed, and other such</p>
                        <p>information.</p>
                        <p>Clear GIFs Information (Web Beacons): When you use the Services, we may employ clear</p>
                        <p>GIFs (also known as web beacons or tracking pixels) to anonymously track online usage</p>
                        <p>patterns. No Personally Identifiable Information from your Which Menu account is collected using</p>
                        <p>these clear GIFs. In addition, we may also use clear GIFs in HTML-based emails sent to our</p>
                        <p>users to track which emails are opened by recipients. The information collected is used to enable</p>
                        <p>more accurate reporting and make Which Menu better for our users.</p>
                        <p>Third Party Services: Which Menu may use third-party services such as Google Analytics,</p>
                        <p>Segment, Fullstory or Amplitude to help understand use of the Services. These services typically</p>
                        <p>collect the information sent by your browser as part of a web page request, including cookies and</p>
                        <p>your IP address. They receive this information and their use of it is governed by their respective</p>
                        <p>privacy policies. You can also prevent the use of Google Analytics relating to your use of our</p>
                        <p>Services by downloading and installing the browser plugin available via this</p>
                        <p>link: <a data-fr-linked="true" href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">http://tools.google.com/dlpage/gaoptout?hl=en-GB</a>.</p>
                        <p>Disclosures: We do not currently respond to web browsers&apos; &quot;do not track&quot; signals that provide a</p>
                        <p>method to opt out of the collection information about users&apos; activities on the Services and on</p>
                        <p>other websites. If we do so in the future, we will provide relevant information in this Privacy &amp;</p>
                        <p>Cookies Policy.</p>
                        <p>WHO WE SHARE YOUR PERSONAL DATA WITH</p>
                        <p>We may share your Personal with third parties in the ways that are described in the table below.</p>
                        <p>We consider this information to be a vital part of our relationship with you.</p>
                        <p>Recipients</p>
                        <p>Our Affiliates</p>
                        <p>Service Providers</p>
                        <p>Professional Advisers</p>
                        <p>Legal and Taxing Authorities,</p>
                        <p>Regulators and Participants in</p>
                        <p>Judicial Proceedings</p>
                        <p>Advertisers</p>
                        <p>Why we share it</p>
                        <p>Our affiliates may access your Personal Data to help us</p>
                        <p>develop, maintain and provide our Services and help</p>
                        <p>manage our customer relationships (including providing</p>
                        <p>customer support, customer liaison etc).</p>
                        <p>Our service providers provide us support for our Services,</p>
                        <p>including, for example, website and application development,</p>
                        <p>hosting, maintenance, backup, storage, virtual infrastructure,</p>
                        <p>payment processing, analysis, identity verification,</p>
                        <p>background and compliance reviews, fund administration,</p>
                        <p>banking services, and other services for us, which may</p>
                        <p>require them to access or use Personal Data about you.</p>
                        <p>Our lawyers, accountants, bankers, auditors and insurers</p>
                        <p>may need to review your personal data to provide</p>
                        <p>consultancy, compliance, banking, legal, insurance,</p>
                        <p>accounting and similar services.</p>
                        <p>Which Menu may disclose your Personal Data if we believe</p>
                        <p>it is reasonably necessary to comply with a law, regulation,</p>
                        <p>order, subpoena, rule of a self-regulatory organization or</p>
                        <p>audit or to protect the safety of any person, to address fraud,</p>
                        <p>security or technical issues, or to protect our legal rights,</p>
                        <p>interests and the interests of others, such as, for example, in</p>
                        <p>connection with the acquisition, merger or sale of securities</p>
                        <p>or a business (e.g. due diligence).</p>
                        <p>Certain users of the Services may have access to your</p>
                        <p>Personal Data for the purpose of enabling them to interact</p>
                        <p>with you and more effectively offer opportunities through the</p>
                        <p>Site that are targeted towards your background and</p>
                        <p>preferences. We may also allow third-parties, including adResearchers</p>
                        <p>API Users</p>
                        <p>servers or ad networks, to serve advertisements on the Site,</p>
                        <p>and such third parties may be provided with access to your</p>
                        <p>Personal Data to provide advertising tailored to your</p>
                        <p>interests.</p>
                        <p>To enhance the public understanding of patterns and trends</p>
                        <p>in the markets served by or Services, we may provide</p>
                        <p>Personal Data to third parties under confidentiality</p>
                        <p>obligations such as, for example, academics or contractors</p>
                        <p>for research purposes.</p>
                        <p>A limited number of partners have API access to portions of</p>
                        <p>the Site. Examples of the most common API uses are</p>
                        <p>UBER.</p>
                        <p>As we develop our business, we may buy or sell businesses or assets. In the event of a</p>
                        <p>corporate sale, merger, reorganization, dissolution or similar event, we may also transfer your</p>
                        <p>Personal Data as part of the transferred assets without your consent or notice to you.</p>
                        <p>We may also share non-Personal Data (such as anonymous usage data, referring/exit pages</p>
                        <p>and URLs, platform types, number of clicks, etc.) with interested third parties to help them</p>
                        <p>understand the usage patterns for certain Services or conduct independent research based on</p>
                        <p>such anonymous usage data.</p>
                        <p>If you request that we remove your Personal Data as described in Your Rights Relating to Your</p>
                        <p>Personal Data, we will convey that request to any third-party with whom we have shared your</p>
                        <p>data. We are not, however, responsible for revising or removing your Personal Data obtained by</p>
                        <p>any third party who has previously been provided your information by us in accordance with this</p>
                        <p>policy or any third party to whom you have provided such information (whether by sharing your</p>
                        <p>login and password, or otherwise).</p>
                        <p>HOW LONG WE STORE YOUR PERSONAL DATA</p>
                        <p>We will retain your information for as long as your account is active or it is reasonably needed for</p>
                        <p>the purposes set out in How We Use Your Personal Data and Why unless you request that we</p>
                        <p>remove your Personal Data as described in Your Rights Relating to Your Personal Data. We will</p>
                        <p>only retain your Personal Data for so long as we reasonably need to use it for these purposes</p>
                        <p>unless a longer retention period is required by law (for example for regulatory purposes). This</p>
                        <p>may include keeping your Personal Data after you have deactivated your account for the period</p>
                        <p>of time needed for us to pursue legitimate business interests, conduct audits, comply with (and</p>
                        <p>demonstrate compliance with) legal obligations, resolve disputes and enforce our agreements.</p>
                        <p>WHERE WE STORE YOUR PERSONAL DATA</p>
                        <p>The Services are maintained in the United Kingdom. Personal Data that you provide us may be</p>
                        <p>stored, processed and accessed by us, our staff, sub-contractors and third parties with whom we</p>
                        <p>share Personal Data in the United Kingdom, elsewhere inside or outside of the EU for the</p>
                        <p>purposes described in this policy. We may also store Personal Data in locations outside the</p>
                        <p>direct control of Which Menu (for instance, on servers or databases co-located with hosting</p>
                        <p>providers). Although we welcome users from all over the world, by accessing the Services and</p>
                        <p>providing us with your Personal Data. Note the laws of the United Kingdom might not be as</p>
                        <p>comprehensive or protective as laws in the country where you live.Because the Services are maintained in the United Kingdom, we do not transfer your Personal</p>
                        <p>Data from the EU to any parties located outside the EU. For further details, see European</p>
                        <p>Commission: Model contracts for the transfer of Personal Data to third countries.</p>
                        <p>HOW WE PROTECT YOUR PERSONAL DATA</p>
                        <p>Which Menu uses industry-standard physical, managerial, and technical safeguards to preserve</p>
                        <p>the integrity and security of your personal information. We limit access to your Personal Data to</p>
                        <p>those employees and other staff who have a business need to have such access. All such</p>
                        <p>people are subject to a contractual duty of confidentiality. We cannot, however, ensure or</p>
                        <p>warrant the security of any information you transmit to Which Menu or guarantee that your</p>
                        <p>information on the Services may not be accessed, disclosed, altered, or destroyed by a breach of</p>
                        <p>any of our physical, managerial, or technical safeguards.</p>
                        <p>We have put in place procedures to deal with any actual or suspected Personal Data breach. In</p>
                        <p>the event that personal information is compromised as a result of such a breach of security,</p>
                        <p>Which Menu will promptly notify those persons whose personal information has been</p>
                        <p>compromised, in accordance with the notification procedures set forth in this Privacy &amp; Cookies</p>
                        <p>Policy, or as otherwise required by applicable law.</p>
                        <p>Which Menu cannot ensure that your Personally Data will be protected, controlled or otherwise</p>
                        <p>managed pursuant to this Privacy &amp; Cookies Policy if you share your login and password</p>
                        <p>information with any third party, including any third party operating a website or providing other</p>
                        <p>services.</p>
                        <p>OUR POLICY ON CHILDRENS PRIVACY</p>
                        <p>Protecting the privacy of young children is especially important. The Services are not intended</p>
                        <p>for children below 16 and Which Menu does not knowingly collect or solicit personal information</p>
                        <p>from anyone under the age of 16 or knowingly allow such persons to register with the Services. If</p>
                        <p>you are under the age of 16, please do not submit any personal information through the Site. We</p>
                        <p>encourage parents and legal guardians to monitor their children&apos;s Internet usage and to help</p>
                        <p>enforce our Privacy &amp; Cookies Policy by instructing their children never to provide personal</p>
                        <p>information on this Site. If we become aware that we have collected personal information from a</p>
                        <p>child under age 16, we will take steps to remove that information.</p>
                        <p>LINKS TO OTHER WEBSITES</p>
                        <p>This Privacy &amp; Cookies Policy applies only to the Services. The Services may contain links to</p>
                        <p>other websites not operated or controlled by Which Menu. We are not responsible for the</p>
                        <p>content, accuracy or opinions expressed in such websites, and such websites are not</p>
                        <p>investigated, monitored or checked for accuracy or completeness by us. Please remember that</p>
                        <p>when you use a link to go from the Services to another website, our Privacy &amp; Cookies Policy is</p>
                        <p>no longer in effect. Your browsing and interaction on any other website, including those that have</p>
                        <p>a link on our Site, is subject to that website&apos;s own rules and policies. Such third parties may use</p>
                        <p>their own cookies or other methods to collect information about you.CHANGES TO OUR PRIVACY &amp; COOKIES POLICY</p>
                        <p>We reserve the right, in our sole discretion, to change, modify, add, or remove portions of this</p>
                        <p>Privacy &amp; Cookies Policy at any time. Any changes or updates will be effective immediately upon</p>
                        <p>posting to this page. You should review this Privacy &amp; Cookies Policy regularly for changes. You</p>
                        <p>can determine if changes have been made by checking the Effective Date below. Your continued</p>
                        <p>use of our Site following the posting of any changes to this Privacy &amp; Cookies Policy means you</p>
                        <p>consent to such changes</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicyPage;
