import React, { useState } from 'react';
import Logo from '../../assets/images/logo.svg';
import { Link } from 'react-scroll';
import { useNavigate } from 'react-router-dom';

function Header() {
  const duration = 500;
  const navigate = useNavigate();
  const [isActive, setActive] = useState('false');
  const handleToggle = () => {
    setActive(!isActive);
  };

  return (
    <React.Fragment>
      <header className={isActive ? 'show' : null}>
        <div className="custom-container">
          <div className="header-inner">
            <div className="logo-wrap" data-aos="zoom-in">
              <a onClick={() => navigate('/')} title="Logo">
                <img src={Logo} alt="Logo" title="Logo" />{' '}
              </a>
            </div>
            <nav className="menu-list" data-aos="fade-up">
              <span className='plr-20'>
                <Link
                  activeClass="active"
                  to="section-1"
                  spy
                  smooth
                  duration={duration}
                  className="nav-link"
                  title="Home"
                  onClick={() => navigate('/')}
                >
                  Home{' '}
                </Link>
              </span>
              
            <span className='plr-20'>
              <Link
                  activeClass="active"
                  to="section-2"
                  spy
                  smooth
                  duration={duration}
                  className="nav-link"
                  onClick={() => navigate('/')}
                  title="Features"
                >
                  {' '}
                  Features{' '}
                </Link>
            </span>
              <span className='plr-20'>
              <Link
                activeClass="active"
                to="section-3"
                spy
                smooth
                duration={duration}
                className="nav-link"
                onClick={() => navigate('/')}
                title="About Us"
              >
                {' '}
                About Us
              </Link>
              </span>
              <span className='plr-20'>
              <Link
                activeClass="active"
                to="section-4"
                spy
                smooth
                duration={duration}
                className="nav-link"
                onClick={() => navigate('/')}
                title="App Tour"
              >
                App Tour
              </Link>
              </span>
              {/* <Link
                activeClass="active"
                to="section-5"
                spy
                smooth
                duration={duration}
                className="nav-link"
                onClick={() => navigate('/')}
                title="Subscription"
              >
                Subscription
              </Link> */}
              <span className='plr-20'>
              <Link
                activeClass="active"
                to="section-6"
                spy
                smooth
                duration={duration}
                className="nav-link"
                onClick={() => navigate('/')}
                title="Contact "
              >
                Contact{' '}
              </Link>
              </span>

              <a
                className="nav-link custom-btn"
                href="https://www.whichmenuapp.com:8080/login"
                target="_blank"
              >
                Login
              </a>
            </nav>

            <div className="hamburger" onClick={handleToggle}>
              <div className="line1"></div>
              <div className="line2"></div>
              <div className="line3"></div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

export default Header;
