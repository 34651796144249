import React, { useEffect, useState } from 'react';
import Faq from 'react-faq-component';
import './Faq.css';

const FaqPage = () => {
  const [rows, setRowsOption] = useState(null);
  const [row, setRow] = useState(0);
  const [type, setType] = useState('R');
  const restaurants = {
    title: '',
    rows: [
      {
        title: 'What is Which Menu?',
        content: `Which Menu is a platform to effectivly help people decide where to eat out, users can search through every menu on the app by simply entering the dish they fancy and it will bring up all the resturants that have it on their menu. Users can also see a description about the restaurant, reviews and any offers if there are any on.`,
      },
      {
        title: 'What type of menus get advertised on Which Menu ?',
        content:
          'Which Menu only advertises sit-down restaurants with official menus. We do not advertise fast-food or takeaway restaurants on our app.',
      },
      {
        title: 'I already have a website with my menu on ?',
        content: `A lot of restaurants do. But finding the menus can be a hassle, especially if a new customer or tourist is looking for food either by dish or by location and doesnt know about your restaurant. Which Menu allows users to easily browse through all menus nearby on the app, searching what specific dish they want.`,
      },
      {
        title: 'I have more than one menu ?',
        content:
          'This is common and not a problem at all, if you are able to get the menu/s combined then you can upload the single document on your bespoke page, or if its easier email us the menus/s you want uploading and we can do it for you!',
      },
      {
        title: 'How do i know if Which Menu is getting me any business?',
        content: `You can view all the statistics of traffic from users on your bespoke page including how many users have veiwed your menu, offers, called up, booked a taxi and of course how many customers have favourited your restaurant on the app. We also advise restaurants to put some exclusive offers on Which Menu so that you know they're coming directly through the app!`,
      },
    ],
  };
  const users = {
    title: '',
    rows: [
      {
        title: 'How do i find a dish i want ?',
        content: `Using the search tool on the top left of the app, simply type the dish you want and then click search! resaturants that have the dish on their menu will populate in order of distance from you! The map view is also available after you have searched a dish allowing you to see what restaurants populate nearby.`,
      },
      {
        title: 'What happens when i favourite restaurants ?',
        content:
          'When you favourite restaurants on Which Menu you will be instantly notified about any offers they put on straight to your device. You wont get notified about restaurants you havent favourited so you will only be kept up to date with the ones you like!',
      },
      {
        title: 'No restaurants are showing up on the app ?',
        content: `Currently we are only in the Liverpool area so if you are trying to veiw restaurants outside the 25 mile radius unfortunaltey no restaurants will appear - but hang on we are branching out to different cities in the near future!`,
      },
    ],
  };
  return (
    <>
      <div className="faqSection">
        <div className="custom-container t-center">
          <h1>FAQ ( Frequently asked questions)</h1>
          <div className="t-center">
            <span
              onClick={() => setType('R')}
              className={type === 'R' && 'active'}
            >
              Restaurants{' '}
            </span>
            <span
              onClick={() => setType('U')}
              className={type === 'U' && 'active'}
            >
              {' '}
              Users
            </span>
          </div>
          <div className="faq-body">
            <div>
              <div className="faq-style-wrapper">
                <Faq
                  data={type === 'R' ? restaurants : users}
                  getRowOptions={setRowsOption}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqPage;
