import { useState } from 'react';

import Header from './components/common/header';
import Footer from './components/common/footer';
import Home from './components/home';
import FaqPage from './components/Faq';
import PrivacyPolicyPage from './components/PrivacyPolicy';
import { Routes, Route, Link } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
