import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
// import { ColorRing } from 'react-loader-spinner';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import images
import Sfimage from '../assets/images/home.png';
import HeadIcon from '../assets/images/head-icon.svg';
import HeadIconWhite from '../assets/images/head-icon-white.svg';
import BookTable from '../assets/images/book-table.svg';
import OrderFood from '../assets/images/order-food.svg';
import SplitPay from '../assets/images/split-pay.svg';
import Discover from '../assets/images/discover.svg';
import GetReward from '../assets/images/get-reward.svg';
import Wallet from '../assets/images/wallet.svg';
import GooglePay from '../assets/images/google-pay.svg';
import AppStore from '../assets/images/app-store.svg';
import ForDinner from '../assets/images/Restaurant-dinner.png';
import ForBusiness from '../assets/images/for-bussiness.svg';
import BgShadow from '../assets/images/bg-shape.png';
import WeWatch from '../assets/images/wewatch.mp4';
import VideoPoster from '../assets/images/video-image.svg';
import PlayButton from '../assets/images/play-icon.svg';

function Home() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState(null);
  const [address, setAddress] = useState('');
  const [message, setMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [seekValue, setSeekValue] = useState(0);
  const videoPlayer = useRef();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState('ddddddd');
  const [loading, setLoading] = useState(false);
  let handleSubmit = (event) => {
    event.preventDefault();

    if (
      username === '' ||
      email === '' ||
      tel === '' ||
      address === '' ||
      message === ''
    ) {
      setError('Please fill all Fields!');
    } else {
      setLoading(true);
      setError('');
      let url = 'http://54.190.192.105:9116/api/admin/send-enquiry-mail';
      let headers = {
        'Content-Type': 'application/json',
      };
      axios
        .post(
          url,
          {
            name: username,
            email: email,
            mobile_number: tel,
            address: address,
            message: message,
          },
          { headers }
        )
        .then((response) => {
          if (response.data.error === null) {
            setSuccess(true);
            setLoading(false);

            setSuccessMsg('Someone will be in touch with you soon!');
            setTimeout(() => {
              setSuccessMsg('');
            }, 3000);
          } else {
            setError('Internal Server Error');
          }
        });
    }
  };
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const play = () => {
    setIsPlaying(true);
    videoPlayer.current.play();
  };

  const pause = () => {
    setIsPlaying(false);
    videoPlayer.current.pause();
  };

  const onPlaying = () => {
    setCurrentTime(videoPlayer.current.currentTime);
    setSeekValue(
      (videoPlayer.current.currentTime / videoPlayer.current.duration) * 100
    );
  };
  return (
    <React.Fragment>
      <section className="firstSection" name="section-1">
        <div className="custom-container">
          <div className="custom-row">
            <div className="custom-col-5" data-aos="slide-up">
              <div className="content-wrap">
                <h1>
                  <span>Find Your</span> <br /> Favorite Dish
                </h1>
                <p>
                 Search for the dish you want from all restaurants
                  nearest to you
                </p>
                <div className="download-wrap">
                  <a
                    href="https://play.google.com/store/apps/details?id=reraisedesign.com.whichmenu"
                    title="Google Pay"
                    data-aos="zoom-in"
                    target="blank"
                  >
                    <img src={GooglePay} alt="Google Pay" title="Google Pay" />
                  </a>
                  <a
                    href="https://itunes.apple.com/us/app/which-menu/id1239180271?ls=1&mt=8"
                    title="App Store"
                    data-aos="zoom-in"
                    target="blank"
                  >
                    <img src={AppStore} alt="App Store" title="App Store" />
                  </a>
                </div>
                <p className="m-0">New version coming soon…</p>
              </div>
            </div>
            <div className="custom-col-7" data-aos="slide-up">
              <img src={Sfimage} alt="Favorite Dish" title="Favorite Dish" />
            </div>
          </div>
        </div>
      </section>

      <section className="benefitSection" name="section-2">
        <div className="custom-container">
          <div className="custom-row">
            <div className="custom-col-4">
              <div className="heading-wrap" data-aos="slide-up">
                <img src={HeadIcon} alt="" title="" />
                <h1>
                  <span>Benefits of</span> <br />
                  Which Menu
                </h1>
              </div>
            </div>
            <div className="custom-col-8">
              <div className="content-wrap" data-aos="slide-up">
              Stay in touch with your favourite restaurants. When you
                favourite a restaurant on the Which Menu App, you will be kept
                up to date with all their latest offers, personally receiving
                app push notifications as soon as they put them on!
              </div>
            </div>
          </div>

          <div className="outer-box">
            <div className="custom-row">
              <div className="custom-col-4" data-aos="fade-up">
                <div className="icon-text">
                  <div className="icon-wrap">
                    <img src={BookTable} alt="Book Table" title="Book Table" />
                  </div>
                  <div className="text-wrap">
                    <h4>Book a Table</h4>
                    <p>
                    Get the inside scoop, see table availability in restaurants and book a table in advance with our built in booking feature.
                    </p>
                  </div>
                </div>
              </div>
              <div className="custom-col-4" data-aos="fade-up">
                <div className="icon-text">
                  <div className="icon-wrap">
                    <img src={OrderFood} alt="Order Food" title="Order Food" />
                  </div>
                  <div className="text-wrap">
                    <h4>Order Food</h4>
                    <p>
                      Order food directly to your table with our in app ordering
                      feature, you can browse through each dish's details, with
                      ingredients and allergens easily available
                    </p>
                  </div>
                </div>
              </div>
              <div className="custom-col-4" data-aos="fade-up">
                <div className="icon-text">
                  <div className="icon-wrap">
                    <img src={SplitPay} alt="Split Pay" title="Split Pay" />
                  </div>
                  <div className="text-wrap">
                    <h4>Split Pay</h4>
                    <p>
                      Large groups? No problem, Manage your bill with ease and
                      confidence with our split pay feature. Create and invite
                      your dining party and decide whether to split the bill
                      evenly or pay individually. No need for calculators or
                      queues for the card machine{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div className="custom-col-4" data-aos="fade-up">
                <div className="icon-text">
                  <div className="icon-wrap">
                    <img
                      src={Discover}
                      alt="Discover New Favorites"
                      title="Discover New Favorites"
                    />
                  </div>
                  <div className="text-wrap">
                    <h4>Discover New Favourites</h4>
                    <p>
                      Looking for something new? Try our Discover feature where
                      we will show nearby announcements from restaurants. From
                      live events to offers, from happy hour to holiday
                      specials. Your newest favourite could be only a click
                      away.{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div className="custom-col-4" data-aos="fade-up">
                <div className="icon-text">
                  <div className="icon-wrap">
                    <img src={GetReward} alt="Get Reward" title="Get Reward" />
                  </div>
                  <div className="text-wrap">
                    <h4>Get Reward</h4>
                    <p>
                      The new loyalty card feature will reward loyal and savvy
                      customers. By following your favourite restaurants and
                      diligently collecting stamps you will reap the benefits
                      and savings too.{' '}
                    </p>
                  </div>
                </div>
              </div>

              <div className="custom-col-4" data-aos="fade-up">
                <div className="icon-text">
                  <div className="icon-wrap">
                    <img src={Wallet} alt="Wallet" title="Wallet" />
                  </div>
                  <div className="text-wrap">
                    <h4>Wallet</h4>
                    <p>
                    Introducing the new Which Menu wallet. Top up your account instantly and pay restaurants directly, reducing fees and ensuring more of your money stays with your favourite business. Our new version also allows you to use Apple
                      Pay or Google Pay.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="thirdSection" name="section-3">
        <div className="custom-container">
          <div className="heading-wrap nospan" data-aos="side-up">
            <img src={HeadIcon} alt="" title="" />
            <h1>Which Menu</h1>
            <h3>
              We love the dining out experience,{' '}
              <span>and we want to share it with everyone</span>
            </h3>
          </div>

          <div className="custom-row">
            <div className="custom-col-5">
              <div className="text-wrap" data-aos="fade-up">
                <p className="diff-text">
                  Which Menu is essentially a hub with all the information you
                  need when deciding where to eat out at your fingertips. Which
                  Menu allows you to browse through nearby restaurants in order
                  of distance from your device bringing up the nearest
                  restaurants first.{' '}
                </p>
                <p className="btm-txt">
                Once you have decided where you want to go, you can easily see a brief description about the restaurant, view their menu as it would be in house, check out their reviews and see any specials they have on.
                </p>
              </div>
            </div>
            <div className="custom-col-7">
              <div className="custom-row">
                <div className="custom-col-6" data-aos="fade-up">
                  <div className="text-wrap">
                    <h4>Boost your visibility</h4>
                    <p>
                      Stand out with our in app marketing tools to reach even
                      more customers and increase sales by attracting more trade
                      on quiet days/hours.
                    </p>
                  </div>
                </div>
                <div className="custom-col-6" data-aos="fade-up">
                  <div className="text-wrap">
                    <h4>Grow Faster </h4>
                    <p>
                    By joining Which Menu and adding your restaurant to the platform, thousands of Which Menu app users could be searching for your food.{' '}
                    </p>
                  </div>
                </div>
                <div className="custom-col-6" data-aos="fade-up">
                  <div className="text-wrap">
                    <h4>Connect direct with customers</h4>
                    <p>
                    Turn customers into regulars with more data insights, exciting announcements or offer a loyalty programme.{' '}
                    </p>
                  </div>
                </div>
                <div className="custom-col-6" data-aos="fade-up">
                  <div className="text-wrap">
                    <h4>Manage with ease </h4>
                    <p>
                    See insights, respond to reviews and take orders with our flexible, manageable software, from the web or from our owners’/managers’ app.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fourthSection" name="section-3">
        <div className="custom-container">
          <div className="custom-row">
            <div className="custom-col-6">
              <div className="heading-wrap" data-aos="slide-up">
                <h1>
                  <span>For Diners </span>
                  <br />
                  Our Customer app
                </h1>
                <p>
                  The App that gives you everything you need in one place, From
                  craving to reviewing, we are here to assist you at every stage
                  of the journey. From last minute dinners to events planned
                  months ahead, for every kind of diner we have something for
                  you, so download today and try it for yourself!{' '}
                </p>
                <div className="download-wrap">
                  <a
                    href="https://play.google.com/store/apps/details?id=reraisedesign.com.whichmenu"
                    title="Google Pay"
                    data-aos="zoom-in"
                    target="blank"
                  >
                    <img src={GooglePay} alt="Google Pay" title="Google Pay" />
                  </a>
                  <a
                    href="https://itunes.apple.com/us/app/which-menu/id1239180271?ls=1&mt=8"
                    title="App Store"
                    data-aos="zoom-in"
                    target="blank"
                  >
                    <img src={AppStore} alt="App Store" title="App Store" />
                  </a>
                </div>
              </div>
            </div>
            <div className="custom-col-6">
              <div className="img-wrap left" data-aos="fade-up">
                <span>
                  <img
                    className="app-img"
                    src={ForDinner}
                    alt="Customer App"
                    title="Customer App"
                  />
                </span>
                <img
                  className="shape-img"
                  src={BgShadow}
                  alt="Customer App"
                  title="Customer App"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fifthSection" name="section-3">
        <div className="custom-container">
          <div className="custom-row">
            <div className="custom-col-6">
              <div className="img-wrap right" data-aos="fade-up">
                <span>
                  <img
                    className="app-img"
                    src={ForBusiness}
                    alt="Customer App"
                    title="Customer App"
                  />
                </span>
                <img
                  className="shape-img"
                  src={BgShadow}
                  alt="Customer App"
                  title="Customer App"
                />
              </div>
            </div>
            <div className="custom-col-6">
              <div className="heading-wrap" data-aos="fade-up">
                <h1>
                  <span>For Business </span>
                  <br />
                  Our restaurant App
                </h1>
                <p>
                  This Restaurant companion app has all the power of the website
                  portal in the palm of your hand. Manage bookings, take orders
                  and edit menu items on the go. You can browse through all your
                  business’ analytics and make announcements quickly when you
                  don’t have access to the portal, or manage your whole business
                  through the app, the choice is always yours. Register and try
                  today!{' '}
                </p>
                <div className="download-wrap">
                  <a
                    className="custom-btn"
                    href="https://whichmenu.com/register"
                    target="_blank"
                  >
                    Register
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="dark-bg" name="section-4">
        <div className="custom-container">
          <div className="heading-wrap" data-aos="slide-up">
            <img src={HeadIconWhite} alt="" title="" />
            <h1>
              <span>How our app works </span>
              <br />
              Our app Tour
            </h1>
          </div>
          <div className="video-wrap" data-aos="fade-up">
            <video
              id="video"
              width="100%"
              height="100%"
              controls
              poster={VideoPoster}
              ref={videoPlayer}
              onTimeUpdate={onPlaying}
            >
              <source src={WeWatch} type="video/mp4" />
            </video>
            <div className="play-button-wrapper" id="circle-play-b">
              {isPlaying ? <a onClick={play} /> : <a onClick={pause} />}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="pricingSection" name="section-5">
        <div className="custom-container">
          <div className="custom-row">
            <div className="custom-col-4">
              <div className="heading-wrap" data-aos="slide-up">
                <img src={HeadIcon} alt="" title="" />
                <h1>
                  <span>Subscription </span>
                  <br />
                  Pricing Plans
                </h1>
              </div>
              <div className="toggle-text-wrap" data-aos="slide-up">
                <p>
                  {' '}
                  Monthly
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </p>
                <p>
                  Yearly <span className="discount">10% discount</span>
                </p>
              </div>
            </div>

            <div className="custom-col-8">
              <div className="custom-row">
                <div className="custom-col-6" data-aos="fade-up">
                  <div className="price-wrap withborder">
                    <h4>Basic</h4>
                    <h2>
                      <span>£</span>2000
                    </h2>
                    <a href="" className="custom-btn" title="Buy Now">
                      Buy Now
                    </a>
                    <ul>
                      <li>Create BoQ</li>
                      <li>Release Purchase Orders</li>
                      <li>Cash flow analysis</li>
                    </ul>
                  </div>
                </div>
                <div className="custom-col-6" data-aos="fade-up">
                  <div className="price-wrap withborder">
                    <h4>Essential</h4>
                    <h2>
                      <span>£</span>5000
                    </h2>
                    <a href="" className="custom-btn" title="Buy Now">
                      Buy Now
                    </a>
                    <ul>
                      <li>Create BoQ</li>
                      <li>Release Purchase Orders</li>
                      <li>Cash flow analysis</li>
                      <li>Vendor App</li>
                      <li>Site Engineer App</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="getInTouch" name="section-6">
        <div className="custom-container">
          <div className="custom-row">
            <div className="custom-col-12">
              <div className="heading-wrap" data-aos="slide-up">
                <img src={HeadIcon} alt="" title="" />
                <h1>
                  <span>Get in Touch </span>
                  <br />
                  Contact Us
                </h1>
              </div>
              {/* <div className="address-wrap" data-aos="slide-up">
                <div className="detail">
                  <h4>Restaurant Reception</h4>
                  <p>
                    <a
                      href="mailto:mail@livekitchen.com"
                      title="mail@livekitchen.com"
                    >
                      mail@livekitchen.com
                    </a>
                  </p>
                  <p>
                    {' '}
                    Call Us -{' '}
                    <a href="tel:01623 030020" title="01623 030020">
                      01623 030020
                    </a>
                  </p>
                  <p>112/B - Road Number 08/A Sydne, Australia</p>
                </div>
                <div className="detail company-wrap">
                  <h4>Company details</h4>
                  <p>
                    <a
                      href="mailto:mail@livekitchen.com"
                      title="mail@livekitchen.com"
                    >
                      mail@livekitchen.com
                    </a>
                  </p>
                  <p>
                    {' '}
                    Call Us -{' '}
                    <a href="tel:01623 030020" title="01623 030020">
                      01623 030020
                    </a>
                  </p>
                  <p>112/B - Road Number 08/A Sydne, Australia</p>
                </div>
              </div> */}
            </div>
            <div className="custom-col-12">
              <div className="form-outer-wrap" data-aos="fade-up">
                {/* <h2>Have a question?</h2> */}
                <div className="form-wrap">
                  <form>
                    <div className="field-wrap">
                      <input
                        type="text"
                        value={username}
                        name="username"
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Name*"
                        required
                      />
                    </div>
                    <div className="field-wrap">
                      <input
                        type="email"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email*"
                        required
                      />
                    </div>
                    <div className="field-wrap">
                      <input
                        type="number"
                        value={tel}
                        name="tel"
                        onChange={(e) => setTel(e.target.value)}
                        placeholder="Phone Number*"
                        required
                      />
                    </div>
                    <div className="field-wrap">
                      <input
                        type="text"
                        value={address}
                        name="address"
                        onChange={(e) => setAddress(e.target.value)}
                        placeholder="Address*"
                        required
                      />
                    </div>
                    <div className="field-wrap textarea">
                      <textarea
                        type="text"
                        value={message}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Message"
                      ></textarea>
                    </div>

                    <div className="btn-wrap">
                      <button
                        type="submit"
                        value="submit"
                        onClick={handleSubmit}
                        className="custom-btn"
                        title="Send"
                        data-aos="flip-right"
                      >
                        {loading ? 'Sending...' : 'Send'}
                      </button>
                      {<p className="error mr-20">{error}</p>}
                      <p className="success mr-20">{success && successMsg}</p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Home;
